import React from 'react'
import ReactDOM from 'react-dom/client'
import App from "./App"
import {baseNameDev,baseNameProd} from "./Components/Constant"
import { BrowserRouter } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<BrowserRouter basename={baseNameProd}><App /></BrowserRouter> );




